export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'regions/edit/'
  },
  { text: 'Регион', value: 'name' },
  { text: 'Бронирование, min', value: 'intermediate_booking_min_pallet', width: '150px' },
  { text: 'Значение оптимал.', value: 'booking_soft_limit_pallet', width: '150px' },
  { text: 'Бронирование, max', value: 'intermediate_booking_max_pallet', width: '150px' }
  // { text: 'Консолид-ое бронирование, min', value: 'consolidation_booking_min_pallet', width: '150px' },
  // { text: 'Консолид-ое бронирование, max', value: 'consolidation_booking_max_pallet', width: '150px' }
]
