<template>
  <div class="return-points-view">
    <p v-if="!returnPoints.length">
      Список пуст
    </p>

    <template v-else>
      <div class="actions">
        <rcc-button @click="selectAllDcs">Выбрать все</rcc-button>
        <rcc-button @click="unselectAllDcs" isOutlined >Отменить все</rcc-button>

        <rcc-select
          v-if="networkId === $constants.networkIds.x5"
          ref="regionsSelect"
          label="Выбрать привязанные к региону"
          :items="regions"
          v-model="region"
          hide-details
          trackBy="id"
          showBy="name"
          class="regions-select"
          @input="handleRegionSelect"
        />
      </div>

      <rcc-checkboxes-group
        :value="selectedReturnPoints"
        choose-by="id"
        show-by="name"
        :options="returnPoints"
        class="checkboxes-grid"
        @input="updateSelectedPointsList"
      />
    </template>
  </div>
</template>

<script>
import RccCheckboxesGroup from 'Components/controls/rcc-checkboxes-group'
import RccButton from 'Components/controls/rcc-button'
import RccSelect from 'Components/controls/rcc-select'

const REGION_FIELD_NAME = 'region_id'

export default {
  name: 'RccReturnPointsView',

  components: {
    RccCheckboxesGroup,
    RccButton,
    RccSelect
  },

  props: {
    networkId: {
      type: Number,
      default: null
    },

    selectedReturnPoints: {
      type: Array,
      default: () => []
    },

    regions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      returnPoints: [],
      region: null
    }
  },

  computed: {
    allReturnPointsIds() {
      return this.returnPoints.map(({ id }) => id)
    }
  },

  mounted() {
    this.getReturnPoints()
  },

  methods: {
    getReturnPoints() {
      if (!this.networkId) {
        return
      }

      this.$apiMethods.returnPoints.dcList({ network_id: this.networkId })
        .then(points => {
          this.returnPoints = points.filter(({ available }) => available)
        })
    },

    updateSelectedPointsList(points) {
      this.$emit('selected-points-change', points)
    },

    selectAllDcs() {
      const newPointsList = Array.from(new Set([...this.selectedReturnPoints, ...this.allReturnPointsIds]))
      this.updateSelectedPointsList(newPointsList)
    },

    unselectAllDcs() {
      const newPointsList = this.selectedReturnPoints.filter(point => !this.allReturnPointsIds.includes(point))

      this.updateSelectedPointsList(newPointsList)
    },

    async handleRegionSelect(value) {
      if (!value) {
        return
      }

      this.unselectAllDcs()
      await this.$nextTick()
      const newPointsList = [
        ...this.selectedReturnPoints,
        ...this.returnPoints.filter(item => item[REGION_FIELD_NAME] === value).map(({ id }) => id)
      ]

      this.updateSelectedPointsList(newPointsList)

      const selectRef = this.$refs.regionsSelect?.$refs.selectRef

      if (selectRef) {
        selectRef.reset()
        selectRef.blur()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.return-points-view {
  margin-top: 10px;

  .actions {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .checkboxes-grid {
    margin-top: 20px;
    width: 90%;

    & > div {
      width: 33%;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;

      & > div {
        width: 50%;
      }
    }

    @media screen and (max-width: 650px) {
      & > div {
        width: 100%;
      }
    }
  }

  .regions-select {
    max-width: 300px;
  }
}
</style>
