<template>
  <div class="returns-management-panel">
    <rcc-button
      v-if="selectedItems.length > 0"
      color="error"
      :is-loading="isSubmit"
      @click="handleApplyClick"
    >
      {{ `Применить (${selectedItems.length} выбрано)` }}
    </rcc-button>

    <rcc-select
      v-if="$store.isReturnsManagementModeEnabled"
      width="200px"
      v-model="selectedManagementOption"
      :items="managementOptions"
      hide-details
      dense
      return-object
    />

    <rcc-button
      color="warning"
      :is-loading="isSubmit"
      @click="toggleManagementMode"
    >
      {{ cancelledModeButtonText }}
    </rcc-button>

    <rcc-confirm-dialog
      :is-show.sync="isShowConfirm"
      :title="confirmDialogData.title"
      :confirm-text="confirmDialogData.confirmText"
      :cancel-text="confirmDialogData.cancelText"
      :confirmCallback="confirmDialogData.confirmCallback"
      @cancel="() => { this.confirmDialogData = {} }"
    />

    <rcc-message-dialog
      :is-show.sync="isShowMessageDialog"
      :message-component-type="messageDialogData.messageComponentType"
      :title="messageDialogData.title"
      :save-text="messageDialogData.saveText"
      :cancel-text="messageDialogData.cancelText"
      :save-callback="messageDialogData.saveCallback"
      :rules="messageDialogData.rules"
      :options="messageDialogData.options"
    />
  </div>
</template>

<script>
import ReturnsApi from '@/api/returns'

import RccButton from 'Components/controls/rcc-button'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'
import RccMessageDialog from 'Components/dialogs/rcc-message-dialog'
import RccSelect from 'Components/controls/rcc-select'

export default {
  name: 'RccReturnsManagementPanel',

  components: {
    RccButton,
    RccConfirmDialog,
    RccMessageDialog,
    RccSelect
  },

  mixins: [ReturnsApi],

  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },

    returns: {
      type: Array,
      default: () => []
    },

    cancelItemsCallback: {
      type: Function,
      default: () => {}
    },

    isSubmit: {
      type: Boolean,
      default: false
    },

    statusesOptions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isShowConfirm: false,
      isShowMessageDialog: false,
      confirmDialogData: {},
      messageDialogData: {},
      selectedManagementOption: {}
    }
  },

  computed: {
    isReturnsManagementModeEnabled() {
      return this.$store.isReturnsManagementModeEnabled
    },

    cancelledModeButtonText() {
      return this.isReturnsManagementModeEnabled ? 'В таблицу возвратов' : 'Управление заявками'
    },

    managementOptions() {
      return [
        { id: 'cancelReturn', value: 'Отмена заявок', handler: this.openCancelReturnsWindow },
        { id: 'changeNumber', value: 'Изменить номер ТС', handler: this.openChangeTsNumberWindow },
        { id: 'removeReturn', value: 'Удаление заявок', handler: this.openRemoveReturnsWindow },
        { id: 'changeStatus', value: 'Изменение статуса заявки', handler: this.openChangeStatusWindow }
      ]
    }
  },

  created() {
    this.selectedManagementOption = this.managementOptions[0]
  },

  methods: {
    toggleManagementMode() {
      this.$store.isReturnsManagementModeEnabled = !this.isReturnsManagementModeEnabled
      this.updateSelectedReturns([])
    },

    updateSelectedReturns(selected) {
      this.$emit('update:selected-items', selected)
    },

    openCancelReturnsWindow() {
      this.confirmDialogData = {
        title: 'Отменить выбранные заявки?',
        confirmText: 'Да',
        cancelText: 'Нет',
        confirmCallback: this.cancelSelectedReturns
      }

      this.isShowConfirm = true
    },

    openRemoveReturnsWindow() {
      this.confirmDialogData = {
        title: 'Удалить выбранные заявки?',
        confirmText: 'Да',
        cancelText: 'Нет',
        confirmCallback: this.removeSelectedReturns
      }

      this.isShowConfirm = true
    },

    openChangeTsNumberWindow() {
      this.messageDialogData = {
        messageComponentType: 'input',
        title: 'Введите номер ТС',
        saveText: 'Принять',
        cancelText: 'Отменить',
        saveCallback: this.handleChangeTsNumber,
        rules: [
          val => val !== '' || 'Не может быть пустым',
          val => val.length <= 15 || 'Номер должен быть не более 15 символов'
        ]
      }

      this.isShowMessageDialog = true
    },

    openChangeStatusWindow() {
      this.messageDialogData = {
        messageComponentType: 'select',
        title: 'Выберите статус',
        saveText: 'Принять',
        cancelText: 'Отменить',
        saveCallback: this.changeStatus,
        rules: [ val => val || 'выберите значение'],
        options: this.statusesOptions
      }

      this.isShowMessageDialog = true
    },

    changeRowsLockStatuses(returns, isCancel = true) {
      const newReturns = this.returns.map(row => {
        return returns.includes(row.id) ? {
          ...row,
          process_status: isCancel
            ? this.$constants.cancelStatuses.IN_PROGRESS
            : this.$constants.cancelStatuses.AVAILABLE
        } :
          row
      })

      this.updateReturns(newReturns)
    },

    lockRowsAndExitManagementMode(rows) {
      this.changeRowsLockStatuses(rows)
      this.$store.isReturnsManagementModeEnabled = false
      this.updateSelectedReturns([])
    },

    cancelSelectedReturns() {
      const cancelled = [...this.selectedItems]
      this.lockRowsAndExitManagementMode(cancelled)

      return this.cancelReturns(this.selectedItems)
        .then(() => {
          window.snackbar('Процесс отмены заявок окончен', 'info')
        })
        .finally(() => {
          this.changeRowsLockStatuses(cancelled, false)
        })
    },

    handleChangeTsNumber(number) {
      const selected = [...this.selectedItems]
      this.lockRowsAndExitManagementMode(selected)
      this.isShowMessageDialog = false

      return this.changeTsNumbers({ order_ids: this.selectedItems, ts_number: number })
        .then(() => {
          window.snackbar('Номера ТС для выбранных заявок успешно изменены', 'info')
        })
        .finally(() => {
          this.changeRowsLockStatuses(selected, false)
        })
    },

    changeStatus(status) {
      const selected = [...this.selectedItems]
      this.lockRowsAndExitManagementMode(selected)
      this.isShowMessageDialog = false

      return this.changeReturnStatuses({ order_ids: this.selectedItems, return_status_id: status })
        .then(() => {
          window.snackbar('Статус для выбранных заявок успешно изменены', 'info')
        })
        .finally(() => {
          this.changeRowsLockStatuses(selected, false)
        })
    },

    removeSelectedReturns() {
      const selected = [...this.selectedItems]
      this.lockRowsAndExitManagementMode(selected)

      return this.removeReturns(this.selectedItems)
        .then(() => {
          window.snackbar('Процесс удаления заявок окончен', 'info')
        })
        .finally(() => {
          this.changeRowsLockStatuses(selected, false)
        })
    },

    updateReturns(returns) {
      this.$emit('update:returns', returns)
    },

    updateIsSubmit(value) {
      this.$emit('update:is-submit', value)
    },

    handleApplyClick() {
      this.selectedManagementOption?.handler()
    }
  }
}
</script>

<style lang="scss" scoped>
.returns-management-panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.rcc-select {
  ::v-deep .v-select__selections {
    padding: 2px !important;
  }

  ::v-deep .v-input__append-inner {
    margin-top: 3px !important;
  }

  ::v-deep .v-input__slot {
    min-height: 0 !important;

    input {
      padding: 3px;
    }
  }
}
</style>
